import { prefetchAppSettingsQuery } from '@/kytesoft-client/features/appSettings';
import { prefetchBlogCategoriesQuery } from '@/kytesoft-client/features/blogCategories';
import { prefetchProductCategoriesQuery } from '@/kytesoft-client/features/productCategories';

import { prefetchBlogsQuery } from '@/kytesoft-client/features/blogs';
import { prefetchProductsQuery } from '@/kytesoft-client/features/products';
import { prefetchQuotesQuery } from '@/kytesoft-client/features/quotes';
import { prefetchSlidersQuery } from '@/kytesoft-client/features/sliders';
import Home from '@containers/Home';
import { QueryClient, dehydrate } from '@tanstack/react-query';
import { LIMIT } from '@utils/limit';

const index = () => <Home />;

export const getStaticProps = async () => {
  const queryClient = new QueryClient();

  await Promise.all([
    prefetchAppSettingsQuery(queryClient),
    prefetchProductCategoriesQuery(queryClient, { queryKey: { location: 'menu-products' } }),
    prefetchBlogCategoriesQuery(queryClient, {
      queryKey: { location: 'main-menu', usedFor: 'blogs' },
    }),

    prefetchBlogsQuery(queryClient, { page: 1, limit: LIMIT.HOME_BLOGS }),
    prefetchProductCategoriesQuery(queryClient),
    prefetchProductsQuery(queryClient, { page: 1, limit: LIMIT.HOME_PRODUCTS }),
    prefetchQuotesQuery(queryClient, { page: 1, limit: 10, site: 1 }),
    prefetchSlidersQuery(queryClient, { site: 1 }),
  ]);

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 60,
  };
};

export default index;
